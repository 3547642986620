<template>
  <div class="home-container">
    <div class="banner">
      <!-- <div class="vertical-dashed"></div>
      <div class="horizontal-dashed"></div> -->
      <div class="banner-content">
        <div class="page-title">
          <div class="page-subtitle">신뢰할 수 있는 개발 파트너를 찾는 현명한 방법</div>
          <div>
            전문가들의 실제 구현 결과물을<br />
            <span class="primary">템플릿</span>으로 확인하세요
          </div>
        </div>
        <div class="search-box">
          <div class="search-box-input">
            <input
              type="text"
              placeholder="템플릿 제목, 사용된 기술, 파트너명으로 검색해 보세요."
              id="template-search-input"
              v-model="searchText"
              @keyup.enter="getSearchResult"
              :style="{ paddingRight: searchText !== '' ? '60px' : '36px' }"
              :autocomplete="'off_' + Math.random().toString(36).substr(2, 9)"
              @focus="removeReadonly"
              ref="searchInput" />
            <img
              v-if="searchText !== ''"
              class="del-button"
              style="width: 16px; height: 16px"
              src="/static/icon/u_close.svg"
              @click="searchText = ''" />
            <img
              class="search-button"
              style="width: 20px; height: 20px"
              src="/static/icon/fi_search.svg"
              @click="getSearchResult" />
          </div>
          <div class="flex-align" style="gap: 24px">
            <div class="body2 sub2">인기 카테고리</div>
            <div class="search-category-wrapper">
              <button
                v-for="(item, idx) in searchCategories"
                :key="`category-${idx}`"
                class="category-button"
                @mouseover="setHovered(item, true)"
                @mouseleave="setHovered(item, false)"
                @click="moveCategory(item)">
                <img class="search-category-img" :src="getImageSrc(item)" />
                <div>
                  {{ item.name }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-img">
      <img src="/static/img/home/pc_template_img.png" />
    </div>
    <div class="create-order-container">
      <div class="create-order-title h4 text-center" style="line-height: 34px">
        내 서비스 맞는 템플릿으로 <span class="reverse-gradient">직접 제작하거나</span><br />
        <span class="reverse-gradient">개발 파트너와 제작</span>해 보세요
      </div>
      <div class="create-order-wrapper">
        <div class="create-order-item">
          <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_search_circle.png" />
          <div class="h8 flex-align" style="gap: 8px">
            <span class="primary">01</span>
            <span class="main">템플릿 탐색</span>
          </div>
          <div class="dot-line-primarylight"></div>
          <div class="body4 sub2 text-center" style="padding: 0 20px; white-space: pre-line; word-break: break-all">
            즉시 개설하여 바로 시작해 볼 수 있는 템플릿부터 원하는 기능을 맞춤 개발 의뢰할 수 있는 템플릿까지!
          </div>
        </div>
        <div class="create-order-item">
          <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_write_circle.png" />
          <div class="h8 flex-align" style="gap: 8px">
            <span class="primary">02</span>
            <span class="main">무료체험 또는 제작 의뢰하기</span>
          </div>
          <div class="dot-line-primarylight"></div>
          <div class="body4 sub2 text-center" style="padding: 0 20px; white-space: pre-line; word-break: break-all">
            플랫폼파이 템플릿은 무료체험으로 바로 개설해 볼 수 있어요. 혹은 내 서비스와 유사한 템플릿을 찾아 제작 의뢰를
            할 수 있어요.
          </div>
        </div>
        <div class="create-order-item">
          <img
            style="width: 60px; height: 60px; object-fit: cover"
            src="/static/img/home/fi_communication_circle.png" />
          <div class="h8 flex-align" style="gap: 8px">
            <span class="primary">03</span>
            <span class="main">파트너 미팅 및 계약</span>
          </div>
          <div class="dot-line-primary"></div>
          <div class="body4 sub2 text-center" style="padding: 0 20px; white-space: pre-line; word-break: break-all">
            제작 의뢰 후 미팅을 통해 내용을 공유할 수 있고 신뢰할 수 있는 파트너인지 확인 후 계약을 진행할 수 있어요.
          </div>
        </div>
        <div class="create-order-item">
          <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_page_circle.png" />
          <div class="h8 flex-align" style="gap: 8px">
            <span class="primary">04</span>
            <span class="main">서비스 제작 및 런칭</span>
          </div>
          <div class="dot-line-primary"></div>
          <div class="body4 sub2 text-center" style="padding: 0 20px; white-space: pre-line; word-break: break-all">
            계약 내용을 바탕으로 서비스 제작을 진행하고 원하던 서비스를 런칭할 수 있어요.
          </div>
        </div>
      </div>
    </div>
    <!-- 제작 순서 -->
    <div class="content-container">
      <div id="scrollTarget"></div>
      <TemplateFilterList
        :categories="categories"
        :tempProducts="tempProducts"
        :searchCategory="searchCategory"
        @selectCategory="selectCategory"
        @openProductFilterModal="openProductFilterModal"
        @clickFreeSetFilter="clickFreeSetFilter"
        @resetFilter="resetFilter"
        @removeFiltered="removefilterd"
        @clickScreenshot="clickScreenshot" />
      <div v-if="tempProducts.length > 0" style="margin-top: 40px">
        <button
          class="button is-main body2-bold"
          style="width: 240px; height: 52px"
          @click="moveCategory(searchCategory)">
          템플릿 전체보기
        </button>
      </div>
    </div>
    <!-- <div class="flex-center" style="margin: 40px 0 120px">
      <div class="flex" style="width: 1152px; gap: 24px">
        <div class="item-survey">
          <div>
            <img src="/static/icon/emoticon_hmm.png" style="width: 40px; height: 40px; margin-bottom: 20px" />
          </div>
          <div class="h6 margin-bottom-20">내 서비스에 맞는 템플릿이 있는지 <br />찾기 어려우신가요?</div>
          <a href="https://walla.my/survey/gZBAG9imMdaiyop6z6It" target="_blank">
            <div class="button-black unselect">템플릿 찾기 도움 요청</div>
          </a>
        </div>
        <div class="item-inquiry">
          <div>
            <img src="/static/icon/ic_inquiry.png" style="width: 40px; height: 40px; margin-bottom: 20px" />
          </div>
          <div class="h6 margin-bottom-20">찾는 테마가 없으시다면?<br />맞춤 플랫폼 제작!</div>
          <a href="https://launchpack.co.kr/prime" target="_blank">
            <div class="button-sub2 unselect">견적문의하기</div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- 추천 파트너 -->
    <div class="partner-container">
      <div class="partner-wrapper">
        <div class="partner-title">
          <div class="h3 main" style="line-height: 50px">특별히 생각하는 기능이 있다면 맞춤 개발 의뢰를 해보세요.</div>
          <div class="body1 main margin-top-12">플랫폼 제작 경험이 많은 개발사들이 함께 하고 있습니다.</div>
        </div>
        <div class="partner-content">
          <div
            class="partner-item unselect"
            v-for="partner in popularPartner"
            :key="`partner-${partner.id}`"
            @click="routerPush(`/partner_detail?id=${partner.id}`)">
            <div class="flex-center" style="flex-direction: column; gap: 2px">
              <div class="h8 main">{{ partner.nickname }}</div>
              <div class="flex-align" style="gap: 2px">
                <div class="body6 sub2">경력</div>
                <div class="body6-medium main">{{ partner.career }}</div>
              </div>
            </div>
            <div class="flex-align" style="gap: 12px">
              <div class="flex-align" style="gap: 4px">
                <img src="/static/icon/u_star-primary.svg" style="width: 14px" />
                <div class="body6-bold main">
                  {{ partner.rate }}
                  <span class="body6 sub3"> ({{ partner.review_count ? partner.review_count : 0 }})</span>
                </div>
              </div>
            </div>
            <div class="tags">
              <div class="tag" v-for="(tag, index) in getLimitedTags(partner.techs)" :key="`tag-${index}`">
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-filter-modal ref="productFilterModal" @apply="applyFilter"></product-filter-modal>
    <screenshot-modal
      ref="screenshotModal"
      :detail="selectedScreenshot"
      @updateBookmark="updateBookmark"
      @clickProdInquiry="clickProdInquiry"></screenshot-modal>
  </div>
</template>

<script>
import TemplateFilterList from '../component/TemplateFilterList.vue';
import ProductFilterModal from '../modal/ProductFilterModal';
import ScreenshotModal from '../modal/ScreenshotModal';

export default {
  name: 'TemplateHomePc',
  components: {
    TemplateFilterList,
    ProductFilterModal,
    ScreenshotModal,
  },
  created() {
    this.getData();
    this.getTemplateCategory();
    this.getPopularPartner();
    this.searchCategories.forEach((item) => {
      this.$set(this.currentImages, item.id, item.img_before);
    });
  },
  mounted() {
    // 검색창에 자동완성 기능 제거
    this.$refs.searchInput.setAttribute('readonly', 'readonly');
    this.disableAutocomplete();
  },
  activated() {},

  data() {
    return {
      searchText: '',
      searchMenus: [
        { name: '오픈마켓' },
        { name: '인력∙용역 마켓' },
        { name: '서비스 예약' },
        { name: '숙박/공간 예약' },
        { name: '배달' },
        { name: '디지털콘텐츠 마켓' },
        { name: 'SNS/커뮤니티' },
        { name: '위치기반 서비스' },
      ],
      searchCategories: [
        {
          created_time: '2024-08-05T15:04:28.338462',
          desc: null,
          id: 2,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27113462.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27113462.png',
          index: 0,
          is_removed: false,
          name: '오픈마켓',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.385489',
          desc: null,
          id: 4,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27765327.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27765327.png',
          index: 2,
          is_removed: false,
          name: '인력∙용역 마켓',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.405700',
          desc: null,
          id: 5,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27114647.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27114647.png',
          index: 3,
          is_removed: false,
          name: '서비스 예약',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.535878',
          desc: null,
          id: 7,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27564273.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27564273.png',
          index: 5,
          is_removed: false,
          name: '숙박/공간 예약',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.558442',
          desc: null,
          id: 8,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27107304.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27107304.png',
          index: 6,
          is_removed: false,
          name: '배달',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.360170',
          desc: null,
          id: 3,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27674949.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27674949.png',
          index: 1,
          is_removed: false,
          name: '디지털콘텐츠 마켓',
          parent_id: 1,
        },
      ],
      categories: [],
      currentImages: {},
      tempProducts: [],
      popularPartner: [],
      searchCategory: null,
      freeFilterBtn: false,
      selectedScreenshot: null,
    };
  },
  methods: {
    updateBookmark(isBookmarked) {
      this.selectedScreenshot.is_liked = isBookmarked;
    },
    clickProdInquiry() {
      if (!this.isLogin) {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.toast('로그인 후 진행해주세요.');
        this.$router.push('/signin');
        return;
      }
      let prodInquiry = {
        product: this.selectedScreenshot,
        option: 1,
      };
      if (this.$store.getters.productInquiry) {
        this.$store.commit('setProductInquiry', null);
      }
      this.$store.commit('setProductInquiry', prodInquiry);
      this.$nextTick(() => {
        this.routerPush('/template_prod_inquiry');
      });
    },
    // 검색창에 자동완성 기능 제거
    disableAutocomplete() {
      const inputs = document.getElementsByTagName('input');
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].autocomplete = 'new-password';
      }
    },
    // 검색창에 자동완성 기능 제거
    removeReadonly() {
      this.$refs.searchInput.removeAttribute('readonly');
    },
    clickScreenshot(card) {
      this.selectedScreenshot = card;
      this.$nextTick(() => {
        this.$refs.screenshotModal.open();
      });
    },
    openProductFilterModal() {
      this.$refs.productFilterModal.open();
    },
    getSearchResult() {
      if (this.searchText.trim() === '') {
        return; // 검색어가 비어있으면 요청하지 않음
      }
      this.$store.commit('setSearch', this.searchText);
      this.searchText = '';
      this.$nextTick(() => {
        this.routerPush('/template_search');
      });
    },
    moveCategory(category) {
      if (category === null) {
        let all = {
          id: 0,
          name: '전체',
          img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
          img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
        };
        this.$store.commit('setTemplateCategory', all);
      } else {
        this.$store.commit('setTemplateCategory', category);
      }
      this.$nextTick(() => {
        this.$router.push('/template_category');
      });
    },
    applyFilter(params) {
      let result = {
        page_num: 1,
        page_length: 12,
        category: 58,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,imgs,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs',
      };
      if (params && params.length > 0) {
        params.forEach((param) => {
          if (param.start) {
            result[`${param.key}_start`] = param.start;
            result[`${param.key}_end`] = param.end;
          } else {
            result[param.key] = Array.isArray(param.value) ? param.value.join() : param.value;
          }
        });
      } else {
        if (result.price_start) {
          delete result[`price_start`];
          delete result[`price_end`];
        }
        result.category = '59,60,61';
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: result }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
      });
    },
    resetFilter(item) {
      this.applyFilter(item);
    },
    removefilterd(item) {
      this.applyFilter(item);
    },
    selectCategory(category) {
      this.searchCategory = category;
      let params = {
        template_categories: category ? category.id : null,
        page_num: 1,
        page_length: 12,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,liked_count,price,visit,img,imgs,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs',
      };
      if (category === null) {
        delete params.template_categories;
        params.category = '59,60,61';
        params.ordering = 'priority,-id';
      } else if (category.id === 0) {
        delete params.template_categories;
        params.category = '59,60,61';
        params.ordering = '-inquiry_count,category_priority,-id';
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
        this.$nextTick(() => {
          // ID를 사용하여 요소를 찾고 스크롤
          const element = document.getElementById('scrollTarget');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
      });
    },
    clickFreeSetFilter(freeFilterBtn) {
      this.freeFilterBtn = freeFilterBtn;
      let params = {
        category: '59,60',
        page_num: 1,
        page_length: 12,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,liked_count,price,visit,img,imgs,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs',
      };
      if (this.freeFilterBtn) {
        params.category = '59,60';
      } else {
        params.category = 58;
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
      });
    },
    getPopularPartner() {
      this.$axios.get('public/partner/popular').then((res) => {
        this.popularPartner = res.data;
      });
    },
    getTemplateCategory() {
      this.$axios.get('public/template_category').then((categories) => {
        this.categories = categories.data;
        this.categories.unshift({
          id: 0,
          name: '추천',
          img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
          img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
        });
        // this.searchCategories = categories.data.filter((category) =>
        //   this.searchMenus.some((menu) => menu.name === category.name)
        // );
      });
    },
    async getData() {
      let params = {
        category: '59,60,61',
        page_num: 1,
        page_length: 12,
        ordering: '-inquiry_count,category_priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,imgs,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      this.searchCategory = {
        id: 0,
        name: '추천',
        img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
        img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
      };
      let user_id = this.$store.state.user.user_id;
      let res = await this.$axios.get(`user/${user_id}/mapping/product`, { params: params });
      this.tempProducts = res.data.data;
      window.scrollTo({
        top: 0,
        // behavior: 'smooth',
      });
    },
    setHovered(item, isHovered) {
      if (isHovered) {
        this.$set(this.currentImages, item.id, item.img_after);
      } else {
        this.$set(this.currentImages, item.id, item.img_before);
      }
    },
    getImageSrc(item) {
      return this.currentImages[item.id] || item.img_before;
    },
    getLimitedTags(tags) {
      if (tags.length <= 3) {
        return tags;
      }
      return tags.slice(0, 3);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
@keyframes img-slider
  from
    transform translateX(0)
  to
    transform translateX(-100%)

.img-slider
  width 677px
  overflow-x hidden
  .img
    animation img-slider 1200s linear infinite
    display flex
    width 53100px
  img
    height 280px
  .img:hover
    animation-play-state paused



.home-container
  margin-top 64px

.platformfy-info-container
  padding 60px 0 100px
  display flex
  align-items center
  gap 0 80px

.info-img-wrap
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 0 50px

.banner
  display flex
  flex-direction column
  padding 120px 144px 80px
  align-items center
  justify-content center
  // background linear-gradient(129deg, #FBFEFF 27.78%, #FFFEFB 37.67%, #FFEDDF 65.75%, #F0F3DD 87.74%)
  text-align center
  position relative

  // &::before
  //   content ''
  //   position absolute
  //   top 8%
  //   left 0
  //   right 0
  //   border-top 1px dashed red

  // &::after
  //   content ''
  //   position absolute
  //   top 0
  //   bottom 0
  //   left 2%
  //   border-left 1px solid red

  // .vertical-dashed
  //   content ''
  //   position absolute
  //   top 0
  //   bottom 0
  //   right 2%
  //   border-right 1px dashed #f00

  // .horizontal-dashed
  //   content ''
  //   position absolute
  //   bottom 8%
  //   left 0
  //   right 0
  //   border-bottom 1px dashed red

.banner-content
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 1152px
  gap 40px

.banner-img
  width 100%
  margin-bottom 120px
  overflow hidden

  img
    width 100%
    height 100%
    object-fit cover

.content-container
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding-bottom 120px

.create-order-container
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 40px
  padding 120px 0
  background-color #FAFAFA
.create-order-wrapper
  display grid
  grid-template-columns repeat(4, 1fr)
  align-items start
  width 1152px

.create-order-item
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 12px

.dot-line-primarylight
  position relative
  width 100%
  height 1px
  border-top 1.5px dashed  #FFA366
  &:after
    content ''
    position absolute
    width 8px
    height 8px
    background-color #FF6600
    border-radius 50%
    top 50%
    left 50%
    transform translate(-50%, -50%)

.dot-line-primary
  position relative
  width 100%
  height 1px
  border-top 1.5px dashed #F60
  &:after
    content ''
    position absolute
    width 8px
    height 8px
    background-color #FF6600
    border-radius 50%
    top 50%
    left 50%
    transform translate(-50%, -50%)

.page-title
  display flex
  align-items center
  justify-content center
  flex-direction column
  gap 8px
  font-weight 700
.gradient-primary
  background linear-gradient(90deg, #F60 46.92%, #D50000 60.89%)
  background-clip text
  -webkit-background-clip text
  -webkit-text-fill-color transparent
.reverse-gradient
  background linear-gradient(90deg, #D50000 59.5%, #F60 100%)
  background-clip text
  -webkit-background-clip text
  -webkit-text-fill-color transparent
.page-title
.page-title-bold
  font-size 44px
  line-height 63px
  color #242428
.page-title-bold
  font-weight 700

.page-subtitle
  font-size 22px
  font-weight 400
  line-height 140%
  letter-spacing -0.044px

.search-box
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 40px

.search-box-input
  position: relative
  display: flex
  align-items: center
  border-radius 8px
  width 100%
  max-width 720px

.search-box-input input
  border none
  outline none
  flex 1
  font-size 16px
  border 1px solid #f60
  padding 12px 36px 12px 16px

.del-button
  position absolute
  top 16px
  right 44px
  cursor pointer


.search-button
  position absolute
  top 14px
  right 16px
  background none
  border none
  cursor pointer

.search-category-wrapper
  display flex
  flex-wrap wrap
  gap 8px
  justify-content center

.category-button
  display flex
  align-items center
  gap 4px
  background #fff
  border 1px solid #ddd
  border-radius 8px
  padding 8px
  font-size 14px
  cursor pointer
  transition background-color 0.3s

button:focus
  border 1px solid #ddd

.search-category-img
  width 18px
  height 18px
  object-fit cover


.banner-white-box
  background-color white
  border-top-left-radius 20px
  border-top-right-radius 20px
  padding 28px 40px 12px 40px
  font-size 16px
  line-height 25.6px


.category-product-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 48px
  width 100%

.proudct-filter-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  gap 24px

.product-filter-item
  display flex
  align-items center
  gap 8px
  width 1152px

.product-list-wrapper
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 48px 24px
  width 1152px

.item-survey
.item-inquiry
  border-radius 8px
  flex 1
  height 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  text-align center
  padding 32px

.item-survey
  background-color $gray3
.item-inquiry
  background-color $subcolor50

.button-black
.button-sub2
  padding 10px 24px
  border-radius 50px
  font-size 15px
  font-weight 700
  color white

.button-black
  background-color #000
.button-sub2
  background-color $subcolor2

.partner-container
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  padding 120px 0
  background-color #F5F5F5
.partner-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 1152px
  gap 48px
.partner-title
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 12px
.partner-content
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 24px
.partner-item
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 8px
  width 100%
  background-color #fff
  border-radius 12px
  padding 32px

.tags
  display flex
  gap 6px
  flex-wrap wrap
  .tag
    border 1px solid $gray
    border-radius 50px
    padding 2px 8px
    color $sub
    font-size 12px


.filter-count
  display flex
  align-items center
  justify-content center
  height 100%
  padding 2px 5px
  border-radius 10px
  border 1px solid #fff
  background #242428
  color #fff
  font-size 10px
  font-weight 600

.sticky
  /*position sticky*/
  top 64px
  z-index 100
  background-color white
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.05)
  .container
    display grid
    grid-template-columns repeat(6, 1fr)

.item-menu
  width 192px
  height 152px
  text-align center
  color $sub2
  display flex
  align-items center
  justify-content center
  border-bottom 2px solid transparent
.item-menu-selected
  color $primary
  border-bottom 2px solid $primary

.line-vertical
  width 1px
  background-color $gray1
  height 32px

.grid-4
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 36px
.grid-3
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-gap 36px

.box-primary-light2
  background-color $primary-light2
  color $primary
  gap 12px
  display flex
  align-items center
  justify-content center
  padding 12px
  border-radius 8px

  .emoticon
    width 40px
    height 40px

a.anchor
  top -62px
  position relative

.img-prime-back
  position absolute
  top -112px
  left calc(50% - 709px)
  width 915px

.grid-slick
  display grid
  grid-template-columns 1fr 453px 1fr
  align-items center
  grid-gap 25px
.slick-img
  height 254px
  width 100%
  background-repeat no-repeat
  background-position center
  background-size contain
.indicators
  display flex
  align-content center
  justify-content center
  gap 12px
  .indicator
    width 6px
    height 6px
    border-radius 100%
    background-color rgba(255, 255, 255, 0.5)
  .indicator-current
    background-color #ffffff
</style>
